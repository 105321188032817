import { defineStore } from 'pinia';
import { FilterOptions } from '~/../sa-help/composables/types/Common';
import { globalFetchOptions } from '~/utils/helpers';
import { ColumnFilter, Pagination, Sort, TsammFilter } from '../types/Common';

export const useFundTransactionStore = defineStore('fundTransaction', () => {
  const filters = ref<{ [key: string]: Array<TsammFilter<string | string[] | boolean | number[]>> }>({
    createdAt: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: 'Created On Or After',
        betweenName: 'Created Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: 'Created On Or Before',
      },
    ],
    action: [
      {
        value: [] as string[],
        operator: 'In',
        name: 'Action',
        options: true,
        valueLabel: [],
      },
    ],
    applicantFirstName: [
      {
        value: '',
        operator: 'Contains',
        name: 'Applicant First Name',
      },
    ],
    applicantLastName: [
      {
        value: '',
        operator: 'Contains',
        name: 'Applicant Last Name',
      },
    ],
    assistanceRequestIdPublic: [
      {
        value: '',
        operator: 'IsEqualTo',
        name: 'Assistance Request ID',
      },
    ],
    assistanceTypeId: [
      {
        value: [] as string[],
        operator: 'In',
        name: 'Assistance Type',
        options: true,
        valueLabel: [],
      },
    ],
    computedAmount: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: 'Amount Greater Than Or Equal To',
        convertFromCents: true,
        betweenName: 'Amount Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: 'Amount Less Than Or Equal To',
        convertFromCents: true,
      },
    ],
    pendingApprovedTotal: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: 'Pending Payments Greater Than Or Equal To',
        convertFromCents: true,
        betweenName: 'Pending Payments Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: 'Pending Payments Less Than Or Equal To',
        convertFromCents: true,
      },
    ],
    balanceFunded: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: 'Issued Payments Greater Than Or Equal To',
        convertFromCents: true,
        betweenName: 'Issued Payments Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: 'Issued Payments Less Than Or Equal To',
        convertFromCents: true,
      },
    ],
    balanceAvailable: [
      {
        value: '',
        operator: 'IsGreaterThanOrEqualTo',
        name: 'Unspent Balance Greater Than Or Equal To',
        convertFromCents: true,
        betweenName: 'Unspent Balance Between',
      },
      {
        value: '',
        operator: 'IsLessThanOrEqualTo',
        name: 'Unspent Balance Less Than Or Equal To',
        convertFromCents: true,
      },
    ],
    fundingSourceId: [
      {
        value: [] as string[],
        operator: 'In',
        name: 'Funding Source',
        options: true,
        valueLabel: [],
      },
    ],
    fundId: [
      {
        value: [] as string[],
        operator: 'In',
        name: 'Fund',
        options: true,
        valueLabel: [],
      },
    ],
    serviceAreaId: [
      {
        value: [] as string[],
        operator: 'In',
        name: 'Service Area',
        options: true,
        valueLabel: [],
      },
    ],
    // userId: [
    //   {
    //     value: [] as string[],
    //     operator: 'In',
    //     name: 'User',
    //     options: true,
    //     valueLabel: [],
    //   },
    // ],
  });

  const columns = ref({
    createdAt: true,
    action: true,
    applicantFirstName: true,
    applicantLastName: true,
    assistanceRequestIdPublic: true,
    assistanceTypeDescription: true,
    computedAmount: true,
    pendingApprovedTotal: false,
    balanceFunded: false,
    balanceAvailable: true,
    fundingSourceName: true,
    fundName: true,
    serviceAreaName: true,
    // userFullName: true,
  });

  const actionList = ref<typeof EnumLedgerActionUserFriendly>(EnumLedgerActionUserFriendly);

  const filterOptions = ref<{
    [key: string]: { label: string | undefined; value: string | undefined | number }[] | undefined;
  }>({
    serviceAreaId: [],
    assistanceTypeId: [],
    fundingSourceId: [],
    fundId: [],
    // userId: [],
    action: Object.keys(actionList.value).map((s) => ({
      label: actionList.value[s as keyof typeof EnumLedgerActionUserFriendly],
      value: s as string,
    })),
  });

  const setFilters = (f: any) => {
    filters.value.createdAt[0].value =
      f.query?.createdAtStart && typeof f.query.createdAtStart === 'string' ? f.query.createdAtStart : '';
    filters.value.createdAt[1].value =
      f.query?.createdAtEnd && typeof f.query.createdAtEnd === 'string' ? f.query.createdAtEnd : '';

    filters.value.action[0].valueLabel =
      f.query?.actionLabels && typeof f.query.actionLabels == 'string' ? f.query.actionLabels.split(',') : [];
    filters.value.action[0].value = f.query?.action && typeof f.query.action === 'string' ? f.query.action.split(',') : '';

    filters.value.applicantFirstName[0].value =
      f.query?.applicantFirstName && typeof f.query.applicantFirstName === 'string' ? f.query.applicantFirstName : '';
    filters.value.applicantLastName[0].value =
      f.query?.applicantLastName && typeof f.query.applicantLastName === 'string' ? f.query.applicantLastName : '';

    filters.value.assistanceRequestIdPublic[0].value =
      f.query?.assistanceRequestIdPublic && typeof f.query.assistanceRequestIdPublic == 'string'
        ? f.query.assistanceRequestIdPublic
        : '';

    filters.value.assistanceTypeId[0].valueLabel =
      f.query?.assistanceTypeIdLabels && typeof f.query.assistanceTypeIdLabels == 'string'
        ? f.query.assistanceTypeIdLabels.split(',')
        : [];
    filters.value.assistanceTypeId[0].value =
      f.query?.assistanceTypeId && typeof f.query.assistanceTypeId === 'string' ? f.query.assistanceTypeId.split(',') : '';

    const as = f.query.computedAmountStart;
    filters.value.computedAmount[0].value =
      f.query?.computedAmountStart && typeof f.query.computedAmountStart === 'string' ? (as > 0 ? as + '' : '') : '';
    const ae = f.query.computedAmountEnd;
    filters.value.computedAmount[1].value =
      f.query?.computedAmountEnd && typeof f.query.computedAmountEnd === 'string' ? (ae > 0 ? ae + '' : '') : '';

    const pats = f.query.pendingApprovedTotalStart;
    filters.value.pendingApprovedTotal[0].value =
      f.query?.pendingApprovedTotalStart && typeof f.query.pendingApprovedTotalStart === 'string'
        ? pats > 0
          ? pats + ''
          : ''
        : '';
    const pate = f.query.pendingApprovedTotalEnd;
    filters.value.pendingApprovedTotal[1].value =
      f.query?.pendingApprovedTotalEnd && typeof f.query.pendingApprovedTotalEnd === 'string'
        ? pate > 0
          ? pate + ''
          : ''
        : '';

    const bfs = f.query.balanceFundedStart;
    filters.value.balanceFunded[0].value =
      f.query?.balanceFundedStart && typeof f.query.balanceFundedStart === 'string' ? (bfs > 0 ? bfs + '' : '') : '';
    const bfe = f.query.balanceFundedEnd;
    filters.value.balanceFunded[1].value =
      f.query?.balanceFundedEnd && typeof f.query.balanceFundedEnd === 'string' ? (bfe > 0 ? bfe + '' : '') : '';

    const bas = f.query.balanceAvailableStart;
    filters.value.balanceAvailable[0].value =
      f.query?.balanceAvailableStart && typeof f.query.balanceAvailableStart === 'string' ? (bas > 0 ? bas + '' : '') : '';
    const bae = f.query.balanceAvailableEnd;
    filters.value.balanceAvailable[1].value =
      f.query?.balanceAvailableEnd && typeof f.query.balanceAvailableEnd === 'string' ? (bae > 0 ? bae + '' : '') : '';

    filters.value.fundingSourceId[0].valueLabel =
      f.query?.fundingSourceIdLabels && typeof f.query.fundingSourceIdLabels == 'string'
        ? f.query.fundingSourceIdLabels.split(',')
        : [];
    filters.value.fundingSourceId[0].value =
      f.query?.fundingSourceId && typeof f.query.fundingSourceId === 'string' ? f.query.fundingSourceId.split(',') : '';

    filters.value.fundId[0].valueLabel =
      f.query?.fundIdLabels && typeof f.query.fundIdLabels == 'string' ? f.query.fundIdLabels.split(',') : [];
    filters.value.fundId[0].value = f.query?.fundId && typeof f.query.fundId === 'string' ? f.query.fundId.split(',') : '';

    filters.value.serviceAreaId[0].valueLabel =
      f.query?.serviceAreaIdLabels && typeof f.query.serviceAreaIdLabels == 'string'
        ? f.query.serviceAreaIdLabels.split(',')
        : [];
    filters.value.serviceAreaId[0].value =
      f.query?.serviceAreaId && typeof f.query.serviceAreaId === 'string' ? f.query.serviceAreaId.split(',') : '';

    // filters.value.userId[0].valueLabel = f.query?.userIdLabels && typeof f.query.userIdLabels == 'string' ? f.query.userIdLabels.split(',') : [];
    // filters.value.userId[0].value =
    //   f.query?.userId && typeof f.query.userId === 'string' ? f.query.userId.split(',') : '';
  };

  const setFiltersAction = (action: string[], serviceAreaIds: string[]) => {
    filters.value.action[0].value = action;
    filters.value.serviceAreaId[0].value = serviceAreaIds;
  };

  const clearFilters = () => {
    for (const key in filters.value) {
      filters.value[key as keyof typeof filters.value].forEach((f: TsammFilter<string | string[] | number[] | boolean>) => {
        if (f.options) f.value = [];
        else f.value = '';
      });
    }
  };

  const getAll = (filter: FilterOptions, pagination: Pagination, sort: Sort) => {
    return $fetch<{ success: boolean; data: FundsLedgersReport[]; count: number }>(
      `/funds/ledgers?${generateFilter(filter)}page=${pagination.currentPage}&pageSize=${pagination.perPage}&sort=${
        sort.id
      }&order=${sort.desc ? 'desc' : 'asc'}`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const exportCsv = async (filter: FilterOptions, sort: Sort, column: ColumnFilter) => {
    return await $fetch<Blob>(
      `/funds/ledgers?&page=1&pageSize=10000&${generateFilter(filter)}&sort=${sort.id}&order=${
        sort.desc ? 'desc' : 'asc'
      }&${generateColumns(column)}`,
      {
        ...globalFetchOptions(),
        method: 'get',
      }
    );
  };

  const openFilterModel = ref(false);
  const setOpenFilterModel = (value: boolean) => {
    openFilterModel.value = value;
  };
  const shownSelectFiltersModal = ref(false);
  const setShownSelectFiltersModal = (value: boolean) => {
    shownSelectFiltersModal.value = value;
  };

  return {
    getAll,
    filters,
    setFiltersAction,
    setFilters,
    actionList,
    clearFilters,
    exportCsv,
    openFilterModel,
    setOpenFilterModel,
    columns,
    shownSelectFiltersModal,
    setShownSelectFiltersModal,
    filterOptions,
  };
});
